import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Button from '../components/Button';
import Img from 'gatsby-image';

const ServiceWrapper = styled.section`
	display: flex;
	flex-direction: column;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0rem;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
		padding: 1rem;
	}
`;

const ServiceNav = styled.section`
	padding: 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 2rem 0 2rem 0;
	}
	flex: 2;
`;

const ServiceNavContainer = styled.div`
	flex-direction: column;
	justify-content: right;
	display: none;
	text-align: right;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-right: 2rem;
		padding-top: 4rem;
		display: flex;
	}
`;

const ServiceList = styled.ul`
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 0;
	text-align: right;
	list-style: none;
`;

const ListItem = styled.li`
	a {
		text-decoration: none;
		color: ${cwTheme.blackHover};

		&:hover {
			color: ${cwTheme.black};
		}
	}
`;

const ServiceDetail = styled.section`
	padding-bottom: 4rem;
	flex: 5;
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 1rem;
`;

const InnerWrapper = styled.div`
	max-width: 600px;
	min-height: 400px;
`;

const ServiceHeader = styled.h3`
	margin-top: 0;
	margin-bottom: .5em;
`;

const ServiceDescription = styled.div`max-width: 600px;`;

const PreviousLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;

  text-decoration: none;
  color: ${cwTheme.black};
  margin-right: 1rem;
  font-weight: 700;
  
  max-width: 300px;
  text-align: right;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  h6{
	   margin-top: 0;
	   font-weight: 600;
  }

  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
  
`;

const NextLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
 
  text-decoration: none;
  color: ${cwTheme.black};
  margin-left: 1rem;
  font-weight: 700;
  
  text-align: left;
  max-width: 300px;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  display: flex;
  flex-direction: column;
  align-content: space-between;
  h6{
	margin-top: 0;
	font-weight: 600;
  }
  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
	
`;

const PostLinks = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
	max-width: 600px;
	margin-top: 5rem;
`;

const ServiceImage = styled.div`margin-bottom: 1rem;`;

export default ({ data, pageContext }) => {
	const { basePath } = pageContext;
	const previous = pageContext.prev;
	const next = pageContext.next;

	const services = data.allContentfulService.edges;

	const { title, body, image, buttonLink, buttonText } = data.contentfulService;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={body.childMarkdownRemark.excerpt} />
				<ServiceWrapper>
					<ServiceNav>
						<ServiceNavContainer>
							<ServiceList>
								{services.map(({ node: service }) => (
									<ListItem>
										<Link
											to={basePath + service.slug}
											activeStyle={{
												color: cwTheme.black
											}}
											partiallyActive={true}
										>
											{service.title}
										</Link>
									</ListItem>
								))}
							</ServiceList>
						</ServiceNavContainer>
					</ServiceNav>
					<ServiceDetail>
						<Container>
							<InnerWrapper>
								<ServiceImage>
									<Img backgroundColor={cwTheme.lightBlue} sizes={image.fluid} />
								</ServiceImage>

								<ServiceHeader>{title}</ServiceHeader>

								<ServiceDescription
									dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
								/>
								<Button link={buttonLink} color={cwTheme.primaryBlue} hoverColor={cwTheme.primaryHover}>
									{buttonText}
								</Button>
							</InnerWrapper>

							{/* <PostLinks>
								{previous ? (
									<PreviousLink to={`${basePath}/${previous.slug}/`}>
										<span>&#8592; Previous</span>
										<h6>{previous.title}</h6>
									</PreviousLink>
								) : (
									<PreviousLink />
								)}
								{next ? (
									<NextLink to={`${basePath}/${next.slug}/`}>
										<span>Next &#8594;</span>
										<h6>{next.title}</h6>
									</NextLink>
								) : (
									<NextLink />
								)}
							</PostLinks> */}
						</Container>
					</ServiceDetail>
				</ServiceWrapper>
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!) {
		allContentfulService(sort: { fields: [sortOrder], order: ASC }) {
			totalCount
			edges {
				node {
					title
					id
					sortOrder
					slug
				}
			}
		}
		contentfulService(slug: { eq: $slug }) {
			body {
				childMarkdownRemark {
					html
					excerpt(pruneLength: 320)
				}
			}
			title
			buttonText
			buttonLink
			image {
				fluid(maxWidth: 1200, maxHeight: 600, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
	}
`;
